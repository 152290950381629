<template>
	<div>
		<h1>Ajuda</h1>
	</div>
</template>

<script>
import mixin from '@/utils/mixinPages'

export default {
	name: 'HelpPage',
	mixins: [mixin],
}
</script>
